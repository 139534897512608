/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-05T13:08:06+02:00
 * @Copyright: Technology Studio
 * @flow
 **/

import React from 'react';
import {Button} from 'components/Button';
import './PlatformOfferCard.css';

type Props = {|
  +node: {
    +frontmatter: {
      +title1: string,
      +title2: string,
      +order: number,
      +priceHod: string,
      +tip: string,
      +btnContact: string,
      +btnColor: string,
      +to: string,
    },
    +html: ?string,
  },
|};

export const PlatformOfferCard = ({
  node: {
    frontmatter: {
      title1, 
      title2, 
      order, 
      priceHod, 
      tip, 
      btnContact, 
      btnColor, 
      to},
    html,
  },
}: Props) => {
  return (
    <div className="txo-platformOfferCard">
      <h3 className="platformOfferCard">
        {title1}
        <br />
        {title2}
      </h3>
      <h2 className="platformOfferCard">
        <sup className="platformOfferCard">€</sup>
        {priceHod}
      </h2>
      <div
        className="txo-platformOfferCard-html"
        dangerouslySetInnerHTML={{__html: html}}
      />
      <h5 className="platformOfferCard">{tip}</h5>
      <div>
        <Button 
          colorVariant={btnColor}
          href={to}
        >
          {btnContact}
        </Button>
      </div>
    </div>
  );
};
