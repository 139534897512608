/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-07-24T14:07:27+02:00
 * @Copyright: Technology Studio
 * @flow
 **/

import React from 'react';
import './FeatureList.css';

export const FeatureList = ({frontmatterList}) => {
  const bullets = Object.keys(frontmatterList)
    .filter(key => frontmatterList[key] !== null)
    .filter(key => key.indexOf('bullet') !== -1);
  
  const cls = suffix =>
    suffix ? `txo-featureList-${suffix}` : `txo-featureList`;

  return (
    <div>
      <div className={cls('bullets')}>
        {bullets.map((bullet, index) => {
          return (
            <div key={index} className={cls('bullet-text')}>
              <div><i className="fa fa-check" /></div>
              <div>{frontmatterList[bullet]}</div>
            </div>
          );
        })}
      </div>
      <div>
        {frontmatterList.star ? (
          <div className={cls('starsList')}>
            <div className={cls('star')}>*</div>
            <div>{frontmatterList.star}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};