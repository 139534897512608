/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-09-03T17:09:91+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import { SimpleInfoDivider } from './SimpleInfoDivider';

export { SimpleInfoDivider };
