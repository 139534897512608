/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-15T09:08:97+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import React from 'react';
import classNames from 'classnames';

import { Parallax } from 'react-scroll-parallax';

import './ParallaxContainer.css';

const cls = suffix => (suffix ? `txo-parallaxContainer-${suffix}` : `txo-parallaxContainer`);

export const ParallaxContainer = ({imageClassName, children}) => {
  if (!imageClassName) {
    return children;
  }
  return (
    <div className={cls()}>
      <Parallax
        className={cls('parallax-section')}
        y={[-30, 30]}
      >
        <div className={classNames(cls('image'), imageClassName)} />
      </Parallax>
      {children}
    </div>
  );
};