/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-26T10:08:99+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import React from 'react';
import {graphql} from 'gatsby';
import { Translation } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-modern-react-i18next';
import TitleAndMetaTags from 'components/TitleAndMetaTags';
import Layout from 'components/Layout';
import createCanonicalUrl from 'utils/createCanonicalUrl';
import {PlatformOfferSection} from 'components/PlatformOfferSection';
import {SimpleInfoDivider} from 'components/SimpleInfoDivider';
import {PlatformServicesSection} from 'components/PlatformServicesSection';
import { ParallaxProvider } from 'react-scroll-parallax';

import './ImagesParallax.css';
import mobil from '../images/mobil1.png';
import web from '../images/computer_small.png';
import backend from '../images/backend_small.png';

const cls = suffix => (suffix ? `txo-imagesParallax-${suffix}` : `txo-imagesParallax`);
type Props = {
  +data: Object,
  +location: Location,
}

const Home = ({data, location}: Props) => {
  const {
    platformOfferSection,
    platformServicesSection,
    platformAppServicesSection,
    platformWebSection,
    platformWebServicesSection,
    platformAutomationSection,
    simpleInfoDivider1,
    simpleInfoDivider2,
    simpleInfoDivider3,
    simpleInfoDivider4,
    simpleInfoDivider5,
    simpleInfoDivider6,
    simpleInfoDivider7,
  } = data;

  return (
    <Translation>
      {t => (
        <ParallaxProvider>
          <Layout location={location}>
            <TitleAndMetaTags
              title="ReactFISH &ndash; Vývoj mobilných a webových aplikácií, backend riešení na mieru"
              canonicalUrl={createCanonicalUrl('/')}
            />
            <SimpleInfoDivider
              {...simpleInfoDivider1.edges[0].node.frontmatter}
              className={cls('1')}
            />
            <PlatformOfferSection 
              edgeList={platformOfferSection.edges}
            />
            <SimpleInfoDivider
              {...simpleInfoDivider3.edges[0].node.frontmatter}
              parallaxImageClassName={cls('img3-parallax')}
            />
            <PlatformServicesSection
              edgeList={platformServicesSection.edges}
              parallaxImageClassName={cls('home-parallax2')}
              imageSource={mobil}
            />
            <SimpleInfoDivider
              {...simpleInfoDivider2.edges[0].node.frontmatter}
              className={cls('2')}
            />
            <PlatformServicesSection
              edgeList={platformAppServicesSection.edges}
              parallaxImageClassName={cls('home-parallax3')}
              imageSource={mobil}
            />
            <SimpleInfoDivider
              {...simpleInfoDivider4.edges[0].node.frontmatter}
              parallaxImageClassName={cls('img4-parallax')}
            />
            <PlatformServicesSection
              edgeList={platformWebSection.edges}
              parallaxImageClassName={cls('home-parallax4')}
              imageSource={web}
            />
            <SimpleInfoDivider
              {...simpleInfoDivider5.edges[0].node.frontmatter}
              parallaxImageClassName={cls('img5-parallax')}
            />
            <PlatformServicesSection
              edgeList={platformWebServicesSection.edges}
              parallaxImageClassName={cls('home-parallax5')}
              imageSource={web}
            />
            <SimpleInfoDivider
              {...simpleInfoDivider6.edges[0].node.frontmatter}
              parallaxImageClassName={cls('img6-parallax')}
            />
            <PlatformServicesSection
              edgeList={platformAutomationSection.edges}
              parallaxImageClassName={cls('home-parallax6')}
              imageSource={backend}
            />
            <SimpleInfoDivider
              {...simpleInfoDivider7.edges[0].node.frontmatter}
              parallaxImageClassName={cls('img7-parallax')}
            />
          </Layout>
        </ParallaxProvider>
      )}
    </Translation>
  );
};

export default withI18next()(Home);

export const pageQuery = graphql`
  query IndexMarkdown($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...LocaleFragment
    }
    platformOfferSection: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/platformOfferSection//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title1
            title2
            priceHod
            tip
            btnContact
            btnColor
            to
          }
          html
        }
      }
    }
    platformServicesSection: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/platformServicesSection//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            price
            price2
            tip
            btnContact
            bullet1
            bullet2
            bullet3
            bullet4
            bullet5
            btnColor
            star
          }
          html
        }
      }
    }
    platformAppServicesSection: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/platformAppServicesSection//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            price
            price2
            tip
            btnContact
            bullet1
            bullet2
            bullet3
            bullet4
            bullet5
            btnColor
            star
          }
          html
        }
      }
    }
    platformWebSection: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/platformWebSection//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            price
            price2
            tip
            btnContact
            bullet1
            bullet2
            bullet3
            bullet4
            bullet5
            btnColor
            star
          }
          html
        }
      }
    }
    platformWebServicesSection: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/platformWebServicesSection//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            price
            price2
            tip
            btnContact
            bullet1
            bullet2
            bullet3
            bullet4
            bullet5
            btnColor
            star
          }
          html
        }
      }
    }
    platformAutomationSection: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/platformAutomationSection//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            price
            price2
            tip
            btnContact
            bullet1
            bullet2
            bullet3
            bullet4
            bullet5
            btnColor
            star
          }
          html
        }
      }
    }
    simpleInfoDivider1: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/simpleInfoDivider1//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            text
          }
        }
      }
    }
    simpleInfoDivider2: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/simpleInfoDivider2//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            text
          }
        }
      }
    }
    simpleInfoDivider3: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/simpleInfoDivider3//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            text
            id
          }
        }
      }
    }
    simpleInfoDivider4: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/simpleInfoDivider4//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            text
            id
          }
        }
      }
    }
    simpleInfoDivider5: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/simpleInfoDivider5//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            text
          }
        }
      }
    }
    simpleInfoDivider6: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/simpleInfoDivider6//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            text
            id
          }
        }
      }
    }
    simpleInfoDivider7: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//home/simpleInfoDivider7//"}
        frontmatter: { language: { eq: $lng } }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            text
          }
        }
      }
    }
  }
`;

