/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-16T10:08:77+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import React from 'react';
import { Button } from 'components/Button';
import { FeatureList } from 'components/FeatureList';
import './PlatformServicesCard.css';

type Props = {|
  +node: {
    +frontmatter: {
      +title: string,
      +price: string,
      +price2: string,
      +tip: string,
      +btnContact: string,
      +btnColor: string,
    },
  },
  +imageSource: string,
|};

const cls = suffix =>
  suffix 
    ? `txo-platformServicesCard-${suffix}` 
    : `txo-platformServicesCard`;

export const PlatformServicesCard = (props: Props) => {
  const {
    node: {
      frontmatter: {
        title,
        price,
        price2,
        tip,
        btnContact,
        btnColor,
      },
    },
    imageSource,
  } = props;

  return (
    <div className={cls()}>
      <div className={cls('pic')}>
        <img 
          className={cls('pic-display-none')} 
          src={imageSource} 
        />
      </div>
      <div className={cls('slide')}>
        <h3 className={cls('title')}>{title}</h3>
        <h2 className={cls('price')}>
          {price2 
            ? null 
            : (
              <div>
                <sup className={cls('eurosign')}>€</sup>
                {price}
                <sup className={cls('asterix')}>*</sup>
              </div>
            )
          }
          {price2 
            ? (
              <div>
                <sup className={cls('eurosign')}>€</sup>
                {price}
              &nbsp;- &nbsp;
                <sup className={cls('eurosign')}>€</sup>
                {price2}
              </div>
            ) 
            : null
          }
        </h2>
        <div className={cls('html')}>{tip}</div>
        <div>
          <FeatureList frontmatterList={props.node.frontmatter} />
        </div>
        <div>
          <Button
            className="btn-padding"
            colorVariant={btnColor}
            href="mailto:contact@react.fish"
          >
            {btnContact}
          </Button>
        </div>
      </div>
    </div>
  );
};
