/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-09T10:08:34+02:00
 * @Copyright: Technology Studio
 * @flow
 **/

import React from 'react';
import { ParallaxContainer}  from 'components/ParallaxContainer';
import { PlatformServicesCard } from 'components/PlatformServicesCard';

import './PlatformServicesSection.css';

type Props = {|
  +imageSource: string,
  +edgeList: Function,
  +parallaxImageClassName: string,
|};

const cls = suffix =>
  suffix
    ? `txo-platformServicesSection-${suffix}`
    : `txo-platformServicesSection`;

export const PlatformServicesSection = ({imageSource, edgeList, parallaxImageClassName}: Props) => (
  <ParallaxContainer 
    imageClassName={parallaxImageClassName}
  >
    <div 
      className={cls()}
    >
      <div>
        {edgeList.map(({node}, index) => (
          <PlatformServicesCard 
            imageSource={imageSource}
            key={index} 
            node={node} 
          />
        ))}
      </div>
    </div>
  </ParallaxContainer>
);
