/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-06T13:08:05+02:00
 * @Copyright: Technology Studio
 * @flow
**/
import { Translation } from 'react-i18next';

import React from 'react';
import { ParallaxContainer}  from 'components/ParallaxContainer';
import { HeaderSectionItem } from 'components/HeaderSectionItem';
import { PlatformOfferCard } from 'components/PlatformOfferCard';

import './PlatformOfferSection.css';

type Props = {
  +edgeList: Function,
}

const cls = suffix => 
  suffix 
    ? `txo-platformOfferSection-${suffix}` 
    : `txo-platformOfferSection`;

export const PlatformOfferSection = ({edgeList} : Props) => (
  <Translation>
    {(t, {i18n}) => {
      return (
        <ParallaxContainer 
          imageClassName={cls('img-parallax')}
        >
          <div 
            id="services"
            className={cls()}
          >
            <HeaderSectionItem 
              heading={t('offer.section.OUR_SERVICES')}
            >
              {t('offer.section.We_will_create_a_mobile_application_for_iOS_and_Android_with_modern_technology_React_Native_DOT')}
            </HeaderSectionItem>
            <div className={cls('offerCard')}>
              {edgeList.map(({node}, index) => (
                <PlatformOfferCard key={index} node={node} />
              ))}
            </div>
          </div>
        </ParallaxContainer>
      );
    }}
  </Translation>
);