/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-07-31T19:07:35+02:00
 * @Copyright: Technology Studio
 * @flow
 **/

import {PlatformServicesCard} from './PlatformServicesCard';

export {PlatformServicesCard};
