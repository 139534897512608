/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-07-30T20:07:69+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import {PlatformOfferSection} from './PlatformOfferSection';

export {PlatformOfferSection};
