/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-15T09:08:97+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import React, { type Node} from 'react';
import classNames from 'classnames';

import { ParallaxContainer } from 'components/ParallaxContainer';


import './SimpleInfoDivider.css';

type Props = {|
  +title: string,
  +id: string,
  +text: Node,
  +className?: string,
  +parallaxImageClassName?: string,
|};

const cls = suffix => 
  suffix 
    ? `txo-simpleInfoDivider-${suffix}`
    : `txo-simpleInfoDivider`;

export const SimpleInfoDivider = ({ 
  title,
  id,
  text, 
  className, 
  parallaxImageClassName, 
}: Props) => (
  <ParallaxContainer 
    imageClassName={parallaxImageClassName}
  >
    <div 
      id={id}
      className={classNames(cls(), className)}
    >
      <h2 className={cls('title')}>
        {title}
        <span className="red-text">.</span>
      </h2>
      <h5 
        className={cls('text')}
        dangerouslySetInnerHTML={{__html: text}} >
      </h5>
    </div>
  </ParallaxContainer>
);